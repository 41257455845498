.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.editable-cell-value-wrap:hover {
  padding: 2px 5px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
